import Card from "react-bootstrap/Card";
import profile from "../content/images/me.png";
import resume from "../content/resume/Resume_Divik.pdf";
import SocialHandles from "./SocialHandles";
import "../App.css";
import Button from "react-bootstrap/Button";
import { FcDocument, FcFeedback } from "react-icons/fc";

function Home() {
  return (
    <div className="flex flex-wrap justify-center mt-20" id="home">
      <div className="flex flex-col z-10">
        <div className="flex pl-8">
        <img
          src={profile}
          width="340"
          height="400"
          className=""
          style={{
            borderRadius: '50%',  // This makes the image oval
            objectFit: 'cover',   // Ensures the image maintains aspect ratio and fits within the given width/height
          }}
          alt=""
        />
        </div>
        <Card
          style={{ width: "28rem" }}
          text="white"
          className="theme-default "
          border="secondary"
        >
          <Card.Body>
            <Card.Title className="flex justify-center">
              DIVIK SHRIVASTAVA
            </Card.Title>
            <Card.Subtitle className="flex justify-center mb-2 text-muted">
              Software Developer | Grad student at SJSU
            </Card.Subtitle>
            <SocialHandles />
          </Card.Body>
        </Card>
      </div>
      <div className="flex flex-col">
        <Card style={{ width: "50rem" }} className="theme-default" text="white">
          <Card.Body>
            <Card.Title>
              <h1>Hi, I'm Divik Shrivastava</h1>
            </Card.Title>
            <Card.Text>
            <h4>
            <p class="m-0 font-light text-xl">
              A Geeko sapien, Grad Student and Software
              Developer, with a knack of developing <b>curiously awesome</b> projects!
            </p>
            </h4>
              <h3>
                Currently seeking Full-time software engineering roles for Summer 2025.
              </h3>
            </Card.Text>
            <a
              href={resume}
              target="_blank"
              rel="noopener noreferrer"
              download="Resume_Divik.pdf"
            >
              <Button> 
                <div className="flex flex-wrap content-start">
                  <FcDocument style={{ fontSize: "25px" }} /> Download my resume
                </div>
              </Button>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="mailto:divikshrivastava@gmail.com">
            <Button>
            <div className="flex flex-wrap content-start">
              <FcFeedback style={{ fontSize: "25px" }} /> Email Me
              </div>
              </Button>
            </a>
            
          </Card.Body>
        </Card>
        <div className="flex flex-wrap">
          <Card
            body
            style={{ width: "25rem" }}
            className="theme-default"
            text="white"
          >
            Interests
            <ul className="list-disc">
              <li>Full Stack Development (MERN/MEAN)</li>
              <li>RESTful Microservices API</li>
              <li>Distributed Systems</li>
              <li>Cloud Computing</li>
              <li>GPU Programming</li>
              <li>Software as a Service</li>
              <li>Database management</li>
              <li>Serverless Design</li>
              <li>AI/ML/DL/Computer Vision</li>
              <li>Large Language Model</li>
            </ul>
          </Card>
          <Card
            body
            style={{ width: "25rem" }}
            className="theme-default"
            text="white"
          >
            Education
            <ul className="list-disc">
             <li>
                San Jose State University <br />
                (Master of Science, Software Engineering, 2023-25)
              </li>
              <li>
                Jaypee Institute of Information Technology, Noida <br />
                (Bachelor of Technology, Computer Science, 2016-20)
              </li>
              <li>
                Delhi Public School, Bhopal <br />
                (12th Grade)
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Home;
