import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faGithub,
  faStackOverflow,
  faKaggle,
  faRedditAlien,
  faHackerrank,
} from "@fortawesome/free-brands-svg-icons";
import "../App.css";

export default function SocialHandles() {
  return (
    <div className="social-container grid grid-cols-5 gap-2">
      <a
        href="https://www.linkedin.com/in/divik-shrivastava/"
        className="linkedin social"
      >
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </a>
      <a href="https://github.com/divikshrivastava" className="github social">
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </a>
      <a
        href="https://stackoverflow.com/users/8244141/divik-shrivastava"
        className="stack social"
      >
        <FontAwesomeIcon icon={faStackOverflow} size="2x" />
      </a>
      <a href="https://www.kaggle.com/divik99" className="kaggle social">
        <FontAwesomeIcon icon={faKaggle} size="2x" />
      </a>
      <a
        href="https://www.hackerrank.com/divikshrivastava"
        className="hackerrank social"
      >
        <FontAwesomeIcon icon={faHackerrank} size="2x" />
      </a>
      <a href="https://www.reddit.com/user/vikid-99" className="reddit social">
        <FontAwesomeIcon icon={faRedditAlien} size="2x" />
      </a>
      <a
        href="https://www.facebook.com/divikshrivastava/"
        className="facebook social"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a
        href="https://www.instagram.com/divikshrivastava"
        className="instagram social"
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCTr7exndCw-SL_vyHlWA9ag"
        className="youtube social"
      >
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a href="https://www.twitter.com/divik1" className="twitter social">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
    </div>
  );
}
